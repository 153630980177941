import React, { type ReactElement } from 'react'
import Button from '@mui/material/Button'
import { ThreeDots } from 'react-loader-spinner'

export const LoadingButton = (props: { id: string, key: string, handler: any, loading: boolean }): ReactElement => {
  return (
    <Button id={props.id} key={props.key} size='small' variant="outlined" disabled={props.loading} onClick={props.handler}>
      {props.loading
        ? <ThreeDots
          height="20"
          width="20"
          radius="2"
          // color="#2196f3"
          ariaLabel="three-dots-loading" visible={true} />
        : 'Confirm'}
    </Button>
  )
}

export const LoadingButton2 = (props: { id: string, key: string, handler: any, loading: boolean, size?: 'small', disabled?: boolean, title?: string }): ReactElement => {
  // console.log(props.disabled, props.loading)
  let btnDisabled = false
  if (props.loading !== null && props.loading !== undefined) {
    btnDisabled = props.loading
  }

  if (props.disabled !== null && props.disabled !== undefined) {
    btnDisabled = props.disabled
  }

  return (
    <Button id={props.id} key={props.key} size={props.size} variant="outlined" disabled={btnDisabled} onClick={props.handler} >
      {props.loading
        ? <ThreeDots
          height="20"
          width="20"
          radius="2"
          // color="#2196f3"
          ariaLabel="three-dots-loading" visible={true} />
        : props.title}
    </Button>
  )
}
