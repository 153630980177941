import React, { type ReactElement } from 'react'
import { Box, Stepper, Step, StepLabel } from '@mui/material'
const steps = ['Access Management', 'Labelled Data Management', 'Unlabelled Data Management']

export const StepperComponent = (props: { activeStep: number }): ReactElement<React.FC> => {
  return (
    <Box sx={{ minWidth: '80%' }}>
      <Stepper activeStep={props.activeStep} >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}
