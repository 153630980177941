import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  Popover
} from '@mui/material'
import React, { type ReactElement } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import LillyLogo from '../Lilly_Red.svg'
import { useMsal } from '@azure/msal-react'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import IconMenu from './ProfileMenu'

export const Layout = (): ReactElement<React.FC> => {
  const { accounts } = useMsal()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return <Box className="h-screen">
    <header>
      <AppBar
        // position="absolute"
        color="inherit"
        elevation={0}
        sx={{
          height: '64px',
          position: 'relative'
        }}
      >
        <Toolbar>
          <Typography sx={{ flexGrow: 1 }} variant="h6" color="inherit" noWrap>
           <ArticleOutlinedIcon color='primary' fontSize='large' /> Language Automatic Data Labeler
          </Typography>
          <Box paddingRight={1}>
            <Button onClick={() => { navigate('/') }} color='secondary' sx={{ fontWeight: 'regular' }} >
              <HomeIcon sx={{
                stroke: 'white',
                strokeWidth: 1
              }} fontSize='large' color='secondary'/> {'Home'}
            </Button>
          </Box>
          <Button aria-describedby={id} onClick={handleClick} color='secondary' sx={{ fontWeight: 'regular' }} >
            <Box paddingRight={1} flexDirection={'row'} display={'flex'} alignItems={'center'}>
              <AccountCircleOutlinedIcon sx={{
                stroke: 'white',
                strokeWidth: 1
              }} fontSize='large' color='secondary' />
              <Box sx={{ display: { xs: 'none', sm: 'block' }, paddingLeft: 1 }}>
                {accounts[0].name}
              </Box>
            </Box>
          </Button>
          <Popover
          sx={{ marginTop: 0.5 }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
        <IconMenu close={handleClose} />
      </Popover>
        </Toolbar>
      </AppBar>

      {/* <h1>GIS Data Labeler Header</h1> */}
    </header>
    <Box
      display={'flex'}
      component={'main'}
      className="bg-slate-100"
      minHeight="calc(100vh - 104px)"
    >
      <Outlet />
    </Box>
    <footer className="h-10 bg-slate-100">
      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography variant="body2" margin={'8px'}>
          Copyright 2023. All rights reserved. Powered By
        </Typography>
        <img src={LillyLogo} alt="Lilly Logo" height={'10px'} width={'40px'} />
      </Box>
    </footer>
  </Box>
}
