import axios, { type AxiosError } from 'axios'

export const httpPost = async (path: string, payload: any, token?: string): Promise<any> => {
  const apiUrl = process.env.REACT_APP_API_URL as string
  if (token !== undefined) {
    return await axios({
      method: 'post',
      url: `${apiUrl}${path}`,
      responseType: 'json',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: payload
    })
      .then((response: any) => {
        if (response.status === 200 || response.status === 201) {
          return [null, response.data]
        } else if (response.data.status === 401) {
          return [response.data, null]
        } else if (response.data.status === 403) {
          return [response.data, null]
        } else {
          if (response?.data !== null) {
            return [response?.data, null]
          } else {
            return [response, null]
          }
        }
      })
      .catch((error: AxiosError) => {
        console.log('error in use api', error)
        if (error.response?.data !== null) {
          return [error.response?.data, null]
        } else {
          return [error, null]
        }
      })
  }
}
