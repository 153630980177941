import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './routers/AppRouter'
import reportWebVitals from './reportWebVitals'
import { MsalProvider } from '@azure/msal-react'
import { type Configuration, PublicClientApplication } from '@azure/msal-browser'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { ThemeProvider, createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#0276aa'
    },
    secondary: {
      main: '#616161'
    }
  }
})

const msalConfiguration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string, // the only mandatory field in this object, uniquely identifies your app
    authority:
      'https://login.microsoftonline.com/18a59a81-eea8-4c30-948a-d8824cdc2580',
    redirectUri: window.location.origin // must be in the list of redirect uris you set in the portal
    // here you'll add the other fields that you might need based on the Azure portal settings
  }
}

const pca = new PublicClientApplication(msalConfiguration)

// axios.interceptors.request.use(
//   async (config: any) => {
//     config.headers = {
//       Authorization: `Bearer ${accessToken}`,
//       Accept: "application/json",
//       "Content-Type": "application/x-www-form-urlencoded",
//     };
//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//   }
// );

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <MsalProvider instance={pca}>
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ThemeProvider>
  </MsalProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
