import { Container, Paper } from '@mui/material'
import React, { type ReactElement } from 'react'
import Markdown from '../components/markdown'
// import EngineeringIcon from '@mui/icons-material/Engineering'
// disable eslint for this line
// eslint-disable-next-line @typescript-eslint/no-var-requires
const userguide = `#### Quick Start

The Language Automatic Data Labeler (LADL) is an officially supported way to securely and automatically label text files for use in training Machine Learning (ML) alogrithms at Eli Lilly.

#### Accessing the LADL

Anyone with a valid Eli Lilly account may access the LADL. We recommend using Google Chrome or Microsoft Edge.

#### Data classification

Any data, including Red PI data, may be used with the LADL.

#### What do I need?

To use the LADL you will need a few things:

- First, you will need a text dataset that you want to label for training ML algorithms
- Second, you will need approximately 10-15 example texts for each label you want to assign to the unlabeled dataset
- Third, you will need to know the impact of the system you are training so as to properly set the threshold for labeling accuracy

#### Labeling Data

- Start by creating a new project and setting all of the relevant information parameters and project collaborators/reviewers.
- Next select the labeled subset of data that you want use as the example texts.
- Then select the unlabeled text dataset you want to apply these labels to automatically.
- After choosing your datasets, you will need to select which pre-processing methods you want to use to clean the data for labeling.
- Finally you will need to select a confidence threshold value that determines which samples need to be reviewed by humans.
- We are now ready to start the labeling job! Once the labeling job is complete you will recieve an email notification that it is ready for reviewers to look at the samples that fell below the specified threshold.
- The larger the threshold value, the more samples will need to be manually reviewed, but this also creates more accurately labeled datasets at the end. This is why it is important to understand the impact severity of the application you're training.
- Reviewers are presented with the text the system saw, the predicted label, and how confident the system is in that label.
- The reviewers then need to select which label best fits the text and move to the next entry.
- Once all entries that fell below the threshold value are reviewed the labeling job is complete!
- You can now download your dataset and use it in training ML models!
- Data will be availble in the system for 30 days after the labeling job is fully complete so it can be re-accessed in case of accidental deletion. However it will be deleted after 30 days to ensure the data remains private since this system is not intended to be a long-term data repository.

#### Issue: Execution Failed

LADL has encountered an "Execution Failed" error. This error can occur due to various reasons, such as CSV file format incompatibility or AWS-related issues.

<b>CSV File Format Incompatibility<b>: The CSV file being utilized does not conform to the required format.

<b>AWS Job Failure<b>: The tool failed due to an error related to AWS (Amazon Web Services).

Resolution Steps:

Check CSV File Format: Please confirm that the CSV file being used adheres to the required format. Ensure that the data structure and format match the tool's specifications. If the file format is incorrect, make the necessary adjustments and try again.

Create a New Project: If the CSV file format is verified to be correct, consider creating a new project to isolate any potential issues. Ensure that all project settings are configured accurately. After setting up the new project, attempt to run the tool again.

Happy Labeling! 🎉

---
`
export const HelpPage = (): ReactElement<React.FC> => {
  return (
    <Container
    maxWidth="lg"
    sx={{ mt: '30px', height: '100%' }}>
    <Paper sx={{ padding: 10 }} >
      <Markdown markdown={userguide} />
    </Paper>
    </Container>
  )
}
