import React, { type ReactElement, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { TableHead, Badge, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { FileDownloadOutlined, InfoOutlined } from '@mui/icons-material'

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void
}

interface Project {
  ProjectMasterId: number
  ProjectName: string
  CreatedDate: string
  userRole: string
  status: string
  actions: string
  badgeColor: 'success' | 'info' | 'warning'
}

interface Props {
  rows: Project[]
}

function TablePaginationActions (props: TablePaginationActionsProps): ReactElement<React.FC> {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props
  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

export const ProjectsTable = (props: Props): ReactElement<React.FC> => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const navigate = useNavigate()

  useEffect(() => { setPage(0) }, [props.rows])

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPage(newPage)
  }

  const onRowClick = (projectId: number, userRole: string, status: string, projectName: string): void => {
    if (status === 'Review Complete') { // TODO: Manual review completed status has to be checked
      // TODO: Preprocessing in progress should be shown on navigation
      navigate(`download/${projectId}`)
    }
    if (status === 'Labeling Complete') {
      navigate(`project/${projectId}/review`)
    }
    if (status === 'Labeling In Progress') {
      navigate(`project/${projectId}/review`)
    }
    if (status === 'In Progress') {
      navigate(`project/${projectId}/review`)
    }
    if (status === 'Pre-processing') {
      // TODO: Preprocessing in progress should be shown on navigation
      navigate(`project/${projectId}/review`)
    }
    if (status === 'Created') {
      if (userRole === 'Admin') {
        // send project name as navigation state
        navigate(`project/${projectId}`, { state: { projectName } })
        return
      }
      navigate(`project/${projectId}/review`)
    }
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  return (
    <Paper >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="projects table">
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell align="center">Project Title</TableCell>
              <TableCell align="center">Created Date</TableCell>
              <TableCell align="center">Role</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : props.rows
            ).map((row, index) => {
              return (
                <TableRow
                  key={index + 1}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {(page) * rowsPerPage + index + 1}
                  </TableCell>
                  <TableCell sx={{ cursor: 'pointer', textDecoration: 'underline', color: '#0276AA' }} onClick={() => { onRowClick(row.ProjectMasterId, row.userRole, row.status, row.ProjectName) }} align="center"><a>{row.ProjectName}</a></TableCell>
                  <TableCell align="center">{row.CreatedDate}</TableCell>
                  <TableCell align="center">{row.userRole}</TableCell>
                  <TableCell align="center">
                    <Badge color={row.badgeColor} variant="dot" sx={{ padding: '1px', marginRight: '15px', color: 'blue' }} />
                    {row.status}
                  </TableCell>
                  <TableCell sx={{ paddingRight: 1 }} align="center">
                      {row.status !== 'Execution Failed' && row.actions}
                      {row.status === 'Execution Failed' &&
                      <Tooltip title='The Job has failed, Please refer to user guide for more details'><InfoOutlined color='error' /></Tooltip>}
                      {row.status === 'Review Complete' &&
                      <FileDownloadOutlined onClick={(): void => { navigate(`/download/${row.ProjectMasterId}`) }} color='info' sx={{ cursor: 'pointer' }} />}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={props.rows.length}
                page={(props.rows.length === 0) || props.rows.length <= 0 ? 0 : page}
                rowsPerPage={rowsPerPage}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper >
  )
}
