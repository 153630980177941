import * as React from 'react'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { useNavigate } from 'react-router-dom'

export default function IconMenu ({ close }: { close: any }): React.ReactElement<React.FC> {
  const navigate = useNavigate()

  return (
    <Paper sx={{ width: 200, maxWidth: '100%' }}>
      <MenuList>
        <MenuItem onClick={() => {
          navigate('/help')
          close()
        }}>
          <ListItemIcon>
            <HelpOutlineOutlinedIcon style={{
              stroke: 'white',
              strokeWidth: 0.5
            }} fontSize="small" />
          </ListItemIcon>
          <ListItemText>User guide</ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
  )
}
