import { create } from 'zustand'

interface Store {
  nextStackEnabled: boolean
  currentStack: number
  nextStack: (data: any) => void
  previousStack: (data: any) => void
  enableNextStack: () => void
  disableNextStack: () => void
  resetCurrentStack: () => void
}

export const useProjectStore = create<Store>()((set) => ({
  currentStack: 0,
  nextStackEnabled: true,
  nextStack: ({ nextStackEnabled }) => {
    set((state) => ({
      currentStack: state.currentStack + 1,
      nextStackEnabled: false
    }))
  },
  previousStack: (data) => {
    set((state) => ({
      currentStack: state.currentStack - 1,
      nextStackEnabled: false
    }))
  },
  enableNextStack: () => {
    set((state) => ({
      nextStackEnabled: true
    }))
  },
  disableNextStack: () => {
    set((state) => ({
      nextStackEnabled: false
    }))
  },
  resetCurrentStack: () => {
    set((state) => ({
      currentStack: 0,
      nextStackEnabled: true
    }))
  }
}))
