/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Alert, AlertTitle, Box, Breadcrumbs, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, InputLabel, Link, List, MenuItem, Paper, Select, Slide, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { type ReactElement, useCallback, useState, useMemo, useEffect } from 'react'
// import { CustomDropzone } from './DropZone'
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse'
import axios from 'axios'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Infom } from './Info'
import { get } from 'http'
import { useProjectStore } from '../hooks/projectwizardStore'
import { NavigateNext } from '@mui/icons-material'

const baseStyle: any = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '60px',
  borderWidth: 2,
  borderRadius: 10,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}
export const LabelledDataUpload = (props: { projectMasterId: number, projectName: string | undefined }): ReactElement<React.FC> => {
  const [data, setData] = useState<any>([])
  const [labelledColumn, setLabelledColumn] = useState('')
  const [dataColumns, setDataColumns] = useState<any[]>([])
  const [csvFile, setCsvFile] = useState<any>()
  const [csvParsed, setCsvParse] = useState<boolean>(false)
  const { instance, accounts } = useMsal()
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [showFileSizeSnackBar, setShowFileSizeSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState<null | string>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dialog, showDialog] = useState<boolean>(false)
  const [uploaded, setUploaded] = useState<boolean>(false)
  const { enableNextStack, disableNextStack } = useProjectStore(state => state)

  useEffect(() => {
    void getProjectDetails()
  }, [])

  const getProjectDetails = async (): Promise<any> => {
    setIsLoading(true)
    const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
    const apiUrl = process.env.REACT_APP_API_URL as string
    if (token !== undefined) {
      const config = {
        method: 'get',
        url: `${apiUrl}/project/${props.projectMasterId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      }
      const response = await axios(config)
      setIsLoading(false)

      if (response.status === 200) {
        const data: any[] = response.data.data
        // if ProjectFileMasterId === 1 then that object is selected for filling the form
        const selectedData = data.filter((item) => item.ProjectFileMasterId === 1)
        if (selectedData.length > 0) {
          const remoteLabelledData: any = selectedData[0]
          // get file name from the string in project file location s3://lly-ladl-1k6clvz1/192/20ng_labeled_subset.csv-cleaned.csv this is the name 20ng_labeled_subset.csv-cleaned.csv
          if (remoteLabelledData.ProjectFileLocation !== null) {
            const fileName = remoteLabelledData.ProjectFileLocation.split('/')[5]
            setCsvFile({ name: fileName })
          }
          if (remoteLabelledData.ProjectDataColumns !== null) {
            const data = remoteLabelledData.ProjectDataColumns.split(',')
            setData(data)
            setDataColumns(data)
            setCsvParse(true)
            if (remoteLabelledData.ProjectLabelledColumn !== null) {
              setLabelledColumn(remoteLabelledData.ProjectLabelledColumn)
              if (!data.includes(remoteLabelledData.ProjectLabelledColumn)) {
                setData([...data, remoteLabelledData.ProjectLabelledColumn])
              }
            }
          }
          setUploaded(true)
          enableNextStack()
        } else {
          disableNextStack()
        }
      }
    }
  }

  const onDrop = useCallback((acceptedFiles: any[]) => {
    setCsvFile(acceptedFiles[0])
  }, [])

  const onProceed = (): void => {
    const reader: any = new FileReader()

    reader.onabort = () => { console.log('file reading was aborted') }
    reader.onerror = () => { console.log('file reading failed') }
    reader.onload = async () => {
      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
      const csv: any = Papa.parse(reader.result, {
        header: true,
        encoding: 'UTF-8',
        complete (results, file) {
          console.log('Parsing complete:', results, file)
        }
      })
      const parsedData: any = csv?.data
      if (parsedData.length > 0) {
        // eslint-disable-next-line prefer-const
        let columns: any = Object.keys(parsedData[0])
        // remove bom character from the first column name
        const bom = '\uFEFF'
        const test = columns[0].replace(bom, '')
        console.log('Bom removed', test)
        const regex = /^[a-zA-Z0-9_ -]*$/
        if (columns.every((col: string) => {
          console.log(regex.test(col), col)
          return regex.test(col)
        })) {
          setData(columns)
          setCsvParse(true)
        } else {
          alert('Invalid column names - Column names can only contain alphanumeric characters, underscores and hyphens')
        }
      } else {
        alert('Not a valid csv file')
        console.log('No Data Found in the csv file')
      }
    }

    reader.readAsText(csvFile, 'UTF-8')
  }

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone(
    {
      onDrop,
      validator: (file) => {
        if (file.type !== 'text/csv') {
          setShowSnackBar(true)
          return {
            code: 'invalid-file-type',
            message: 'only CSV files are allowed'
          }
        }
        if (file.size > 6000000) {
          setShowFileSizeSnackBar(true)
          return {
            code: 'size-too-large',
            message: 'file is larger than 6MB'
          }
        }
        return null
      },
      maxFiles: 1,
      useFsAccessApi: false,
      accept: {
        'text/csv': ['.csv']
      }
    })
  const style: any = useMemo(() => ({
    ...baseStyle,
    ...((isFocused) ? focusedStyle : {}),
    ...((isDragAccept) ? acceptStyle : {}),
    ...((isDragReject) ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ])
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    console.log(event.target.value, event.target.checked)
    const { value, checked }: { value: string, checked: boolean } = event.target
    if ((checked && !dataColumns.includes(value)) || dataColumns.length === 0) {
      setDataColumns(prevState => [...prevState, value])
    }

    if (!checked && dataColumns.length > 0 && dataColumns.includes(value)) {
      console.log('removed' + value)
      const tempDataColumns: any = dataColumns.filter(x => x !== value)
      setDataColumns(tempDataColumns)
    }
  }

  function pick (obj: any, keys: any): any {
    const result: any = {}
    for (const key of keys) {
      result[key] = obj[key]
    }
    return result
  }

  const getAccessToken = async (scope: string): Promise<string | undefined> => {
    if (accounts.length > 0) {
      const request = {
        scopes: [`${scope}`],
        account: accounts[0]
      }
      try {
        const response = await instance.acquireTokenSilent(request)
        return response.accessToken
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request)
          return response.accessToken
        }
      };
    }
  }

  const uploadFile = (): void => {
    console.log(dataColumns, labelledColumn)
    if (dataColumns.length === 0) {
      alert('Please select Data Columns')
      return
    }
    if (labelledColumn === '') {
      alert('Please select labelled column')
      return
    }
    if (csvFile === undefined) {
      alert('Please select a File')
      return
    }
    showDialog(true)
  }

  const dialogProceed = (): void => {
    showDialog(false)
    setIsLoading(true)
    const reader: any = new FileReader()
    reader.onabort = () => { console.log('file reading was aborted') }
    reader.onerror = () => { console.log('file reading failed') }
    reader.onload = async () => {
      const csv: any = Papa.parse(reader.result, {
        header: true
      })
      const parsedData: any = csv?.data
      // console.log(parsedData)
      // set labelledColumn as first column in dataColumns
      const labelledAsFirstColumn = dataColumns.filter((col: string) => col !== labelledColumn)
      labelledAsFirstColumn.unshift(labelledColumn)
      // console.log('tempdata columns', tempDataColumns)
      const filteredData = parsedData.map((data: any) => pick(data, labelledAsFirstColumn))
      const cleanedUpCsv = Papa.unparse(filteredData)
      console.log(cleanedUpCsv)
      const blob = new Blob([cleanedUpCsv], { type: 'text/csv' })
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const file = new File([blob], `${csvFile.name}`, { type: 'text/csv' })
      // upload file from here
      console.log(file)
      const formData = new FormData()
      formData.append('csv', file)
      const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
      const apiUrl = process.env.REACT_APP_API_URL as string
      if (token !== undefined) {
        const config = {
          method: 'post',
          url: `${apiUrl}/dataset/upload?projectId=${props.projectMasterId}&labelledColumn=${encodeURIComponent(labelledColumn)}&dataColumns=${encodeURIComponent(dataColumns.filter(Boolean).join(','))}&delimiter=,`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          },
          data: formData
        }
        const response = await axios(config)
        console.log(response)
        setIsLoading(false)
        if (response.status === 200) {
          setSnackBarMessage('File uploaded Successfully')
          await getProjectDetails()
        }
      }
    }
    console.log('csv File parsed', csvFile)

    reader.readAsText(csvFile)
  }

  const clearForm = (): void => {
    setDataColumns([])
    setLabelledColumn('')
    setCsvFile(undefined)
    setCsvParse(false)
    setUploaded(false)
    setData([])
    disableNextStack()
  }

  return <Paper variant="outlined" style={{ padding: '20px', height: '80%' }} className="mt-5">
    <Breadcrumbs separator={<NavigateNext fontSize="small" />} sx={{ mb: 3 }} aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
          <Typography fontSize={18} sx={{ fontWeight: '500' }}>{props.projectName}</Typography>
          </Link>
          <Typography fontSize={18} sx={{ fontWeight: '500' }} color="text.primary">Labelled data upload</Typography>
        </Breadcrumbs>
    { isLoading
      ? <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}><CircularProgress /></Box>
      : <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={2}
      sx={{ height: '90%' }}
      alignItems={'center'}
      justifyContent={'space-evenly'}
    >
      <Box width={'40%'} display={'flex'} flexDirection={'column'} >
        <Typography fontWeight={'bold'} paddingBottom={2}>
          Document Upload  <Infom info='Please select the data set file you wish to upload to act as the Labelled data. Please note only .csv file types are currently accepted.' />
        </Typography>
        {csvFile === undefined && <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p><span style={{ color: '#0276aa' }}>Click to select files </span>or drag and drop .csv file up to 6MB</p>
        </div>}
        {csvFile !== undefined && <div style={baseStyle}><Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
          <ArticleOutlinedIcon fontSize='large' sx={{ alignSelf: 'center' }} />
          <p className='text-slate-500'>{csvFile.name} <HighlightOffOutlinedIcon onClick={clearForm} fontSize='small' sx={{ alignSelf: 'center', cursor: 'pointer' }} /></p>
        </Box></div>}
        <Typography fontWeight={'bold'} paddingY={'10px'}>
          Delimiter <Infom info='This is currently restricted to Comma as only .csv files are accepted currently' />
        </Typography>
        <TextField disabled={uploaded} id="delimiter" value={'comma'} variant="outlined" />
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
        <Button sx={{ mt: 4, mx: 2, alignSelf: 'flex-end' }} size='large' variant='outlined' disabled={csvFile === undefined || uploaded} onClick={clearForm} >Cancel</Button>
        <Button sx={{ mt: 4, alignSelf: 'flex-end' }} size='large' variant='contained' disabled={csvFile === undefined || uploaded || csvParsed} onClick={onProceed} >Proceed</Button>
        </Box>
        {/* {showSnackBar && <Alert severity="error"> <AlertTitle>Invalid file</AlertTitle>Only CSV files are supprorted</Alert>} */}
      </Box>
      {csvParsed && <Slide direction="left" timeout={500} in mountOnEnter unmountOnExit>
        <Box width={'40%'}>
        <Typography fontWeight={'bold'} paddingBottom={'5px'}>
          Select Data Columns <Infom info='Please select the data columns you wish the automated process to review in this file' />
        </Typography>
        <FormGroup>
        <List style={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'scroll',
          maxHeight: '200px',
          borderWidth: 2,
          borderRadius: 2,
          borderColor: '#eeeeee',
          padding: '10px'
        }} >
          {data.map((col: any, idx: any) => <FormControlLabel disabled={uploaded} key={idx} control={<Checkbox checked={dataColumns.includes(col)} value={col} onChange={handleChange} />} label={col} />)}
        </List>
        </FormGroup>
        {/* {data.map((col, idx) => <div key={idx}>{col}</div>)} */}
        <Typography fontWeight={'bold'} paddingY={'15px'}>
          Select Labelled Column <Infom info='Please select the appropriate labelled data column in this file.' />
        </Typography>
        <FormControl fullWidth>
          <InputLabel shrink={false} id="demo-simple-select-label">{labelledColumn === '' ? 'Select Category column' : ''}</InputLabel>
          <Select
            disabled={uploaded}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={labelledColumn}
            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
            inputProps={{ shrink: 'false' }}
            onChange={(event) => {
              setLabelledColumn(event.target.value)
            }}
          >
            {data.map((col: any, idx: any) => <MenuItem key={idx} value={col}>{col}</MenuItem>)}
          </Select>
        </FormControl>
        <Button disabled={uploaded} sx={{ mt: 2 }} size='large' variant='contained' onClick={uploadFile} >Upload</Button>
      </Box></Slide>}
    </Stack>}
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={showFileSizeSnackBar} onClose={() => { setShowFileSizeSnackBar(false) }} autoHideDuration={6000} >
      <Alert sx={{ width: '100%' }} variant="filled" severity="error"> <AlertTitle>File size exceeds limit</AlertTitle>File size cannot exceed 6MB limit</Alert>
    </Snackbar>
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={showSnackBar} onClose={() => { setShowSnackBar(false) }} autoHideDuration={6000} >
      <Alert sx={{ width: '100%' }} variant="filled" severity="error"> <AlertTitle>Invalid file</AlertTitle>Only CSV files are supprorted</Alert>
    </Snackbar>
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackBarMessage !== null} onClose={() => { setSnackBarMessage(null) }} autoHideDuration={5000} >
      <Alert sx={{ width: '100%' }} variant="filled" severity="success"> <AlertTitle>Success</AlertTitle>{snackBarMessage}</Alert>
    </Snackbar>
    <Dialog
        open={dialog}
        onClose={() => { showDialog(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{ px: 8, pt: 6 }}
        >
          <DialogContentText id="alert-dialog-description">
            Do you want to upload the document?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={() => { showDialog(false) }}>
            No
          </Button>
          <Button variant='contained' onClick={() => { dialogProceed() }} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
  </Paper>
}
