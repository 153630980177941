import React, { type ReactElement, useState } from 'react'
import {
  Paper,
  Typography,
  Container,
  TextField,
  Button,
  Box,
  CircularProgress,
  Tooltip,
  Grow
} from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { httpPost } from '../hooks/useApi'
import { useMsal } from '@azure/msal-react'
import { InteractionRequiredAuthError } from '@azure/msal-common'
import { SnackbarContext } from '../hooks/snackContext'

export const CreateProjectPage = (): ReactElement<React.FC> => {
  const { instance, accounts } = useMsal()
  const [projectName, setProjectName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { setSnackMessage, setType } = React.useContext(SnackbarContext)

  const handleProjectNameChange = (e: { target: { value: string } }): void => {
    setProjectName(e.target.value)
  }

  const getAccessToken = async (scope: string): Promise<string | undefined> => {
    if (accounts.length > 0) {
      const request = {
        scopes: [`${scope}`],
        account: accounts[0]
      }
      try {
        const response = await instance.acquireTokenSilent(request)
        return response.accessToken
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request)
          return response.accessToken
        }
      }
    }
  }

  const handleSubmit = async (): Promise<void> => {
    // Submit the project name to the API
    // Replace this with your API logic
    setIsLoading(true)
    const token = await getAccessToken(
      process.env.REACT_APP_API_SCOPE as string
    )

    const [error, response] = await httpPost(
      '/projects/create',
      {
        ProjectName: projectName
      },
      token
    )
    if (error !== null) {
      console.log('Error', error)
      if (error?.messsage !== undefined) {
        setSnackMessage(error?.messsage)
      } else {
        setSnackMessage(error.toString())
      }
      setType('error')
      setIsLoading(false)
    } else {
      setSnackMessage('Project Created Successfully!')
      setType('success')
      console.log('CreatedProject ::', response)
      if (response.data.ProjectMasterId !== undefined) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        navigate(`/project/${response.data.ProjectMasterId.toString()}`, { state: { projectName } })
      }
      setIsLoading(false)
    }
  }

  const handleCancel = (): void => {
    navigate('/')
  }

  return (
    <Container maxWidth={false}>
      <Container
        maxWidth="md"
        sx={{ mt: '60px' }}
      >
        {isLoading && <Box display={'flex'} alignItems={'center'} justifyContent={'center'} paddingTop={'100px'}>
          <CircularProgress />
        </Box>
        }
        {!isLoading && <Grow in={true} timeout={1000}><Paper variant="outlined" style={{ padding: '100px' }}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{ pb: '20px' }}
          >
            Create Project
          </Typography>

          <Box mb={3}>
            <TextField
              label="Project Name"
              variant="outlined"
              fullWidth
              value={projectName}
              onChange={handleProjectNameChange}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end">

            <Button
              variant="outlined"
              color="secondary"

              onClick={handleCancel}
              style={{ marginRight: '10px' }}
              size="large"
            >
              Cancel
            </Button>
            <Tooltip title={projectName.length <= 0 ? 'Project Name should not be empty' : 'Create New Project' }>
              <Button
                size="large"
                variant="contained"
                color="primary"
                disabled={projectName.length <= 0}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Tooltip>
          </Box>
        </Paper></Grow>}
      </Container>
    </Container>
  )
}
