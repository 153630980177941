import React, { type ReactElement, useState, useEffect } from 'react'
import {
  Paper,
  Typography,
  TextField,
  Box,
  InputLabel,
  Button,
  List,
  ListItem,
  ListItemButton,
  CircularProgress,
  Snackbar,
  AlertTitle,
  Alert,
  Breadcrumbs,
  Link
} from '@mui/material'
import axios from 'axios'
import { useMsal } from '@azure/msal-react'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { AssignedUsersTable } from './AssignedUsersTable'
import { useProjectStore } from '../hooks/projectwizardStore'
import { NavigateNext } from '@mui/icons-material'

interface Props {
  showProjectCreateForm: boolean
  projectMasterId: number | undefined
  projectName?: string
}

interface User {
  name: string
  id: string
  systemId: string
}

interface RawUser {
  onPremisesSamAccountName: string
  id: string
  displayName: string
  mail: string
  userRoleMasterId: number
  projectMasterId: number
}

interface UserResponse {
  data: {
    data: [[{
      UserName: string
      UserId: number
      UserGlobalId: string
    }]]
  }
}

export const CreatProjectForm = (props: Props): ReactElement<React.FC> => {
  const { instance, accounts } = useMsal()
  const [searchQuery, setSearchQuery] = useState('')
  const [searchedUsers, setSearchUsers] = useState<User[]>([])
  const [assignedUsers, setAssignedUsers] = useState<User[]>([])
  const [selectedUser, setSelectedUser] = useState<User>()
  const [showAddUsersSuccessToast, setShowAddUsersSuccessToast] = useState(false)
  const [showNoUsersErrorToast, setShowNoUsersErrorToast] = useState(false)
  const [showUnableToAddUsersToast, setShowUnableToAddUsersToast] = useState(false)
  const [showUnableToDeleteUsersToast, setShowUnableToDeleteUsersToast] = useState(false)
  const [showDeleteUsersToast, setDeleteUsersToast] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { enableNextStack } = useProjectStore(state => state)

  const getAssignedUsers = async (): Promise<void> => {
    try {
      enableNextStack()
      setIsLoading(true)
      const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
      const apiUrl = process.env.REACT_APP_API_URL as string
      if (token !== undefined && props.projectMasterId !== undefined) {
        const config = {
          method: 'get',
          url: `${apiUrl}/projects/users?projectMasterId=${props.projectMasterId}`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
        const response: UserResponse = await axios(config)
        if (response.data !== null && response.data.data.length > 0) {
          const users: User[] = response.data.data[0].map((user) => {
            return {
              name: user.UserName,
              id: user.UserId.toString(),
              systemId: user.UserGlobalId
            }
          })
          setAssignedUsers(users)
          setIsLoading(false)
        }
      }
    } catch (err) {
      console.log(err)
      setSearchQuery('')
    }
  }

  useEffect((): void => {
    // eslint-disable-next-line
    (async (): Promise<void> => {
      await getAssignedUsers()
    })()
  }, [])

  const handleUserNameInput = async (e: {
    target: {
      value: string
    }
  }): Promise<void> => {
    setSearchQuery(e.target.value)
    if (e.target.value.length > 0) {
      const searchedUsers = filterSelectedUsers(await getUsersFromAD(e.target.value) as User[])
      setSearchUsers(searchedUsers)
    } else {
      setSearchQuery('')
      setSelectedUser(undefined)
      setSearchUsers([])
    }
  }

  const handleUserInputBackspace = async (e: { key: string }): Promise<void> => {
    const { key } = e
    if (['Backspace', 'Delete'].includes(key)) {
      setSelectedUser(undefined)
    }
  }
  const deleteAssignedUser = async (id: string): Promise<void> => {
    // make API call and store Users
    const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
    const apiUrl = process.env.REACT_APP_API_URL as string
    if (token !== undefined) {
      const config = {
        method: 'post',
        url: `${apiUrl}/users/delete`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: id
      }
      try {
        await axios(config)
        setSearchQuery('')
        setDeleteUsersToast(true)
      } catch (err) {
        console.log(err)
        setShowUnableToDeleteUsersToast(true)
        setSearchQuery('')
      }
    } else {
      setShowUnableToDeleteUsersToast(true)
      setSearchQuery('')
    }
    await getAssignedUsers()
  }

  const handleAddUsers = async (): Promise<void> => {
    if (selectedUser === undefined) {
      setShowNoUsersErrorToast(true)
    } else {
      // make API call and store Users
      setIsLoading(true)
      const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
      const apiUrl = process.env.REACT_APP_API_URL as string
      if (token !== undefined) {
        const config = {
          method: 'post',
          url: `${apiUrl}/users/add`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          data: [selectedUser]
        }
        try {
          await axios(config)
          setSearchQuery('')
          setSelectedUser(undefined)
          setIsLoading(false)
          setShowAddUsersSuccessToast(true)
          await getAssignedUsers()
        } catch (err) {
          console.log(err)
          setShowUnableToAddUsersToast(true)
          setSearchQuery('')
        }
      } else {
        setShowUnableToAddUsersToast(true)
        setSearchQuery('')
      }
    }
  }

  const handleUserSelect = (selectedUser: User): void => {
    setSelectedUser(selectedUser)
    setSearchUsers([])
    setSearchQuery(selectedUser.name)
  }

  const filterSelectedUsers = (searchedUsers: User[]): User[] => {
    return searchedUsers.filter((searchedUser: User) => !assignedUsers.some(assignedUser => searchedUser.systemId === assignedUser.systemId))
  }

  const getAccessToken = async (scope: string): Promise<string | undefined> => {
    if (accounts.length > 0) {
      const request = {
        scopes: [`${scope}`],
        account: accounts[0]
      }
      try {
        const response = await instance.acquireTokenSilent(request)
        return response.accessToken
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request)
          return response.accessToken
        }
      };
    }
  }

  const getUsersFromAD = async (username: string): Promise<User[] | undefined> => {
    const token = await getAccessToken('User.Read')
    let rawUsers: RawUser[] = [{
      onPremisesSamAccountName: '',
      id: '',
      displayName: '',
      mail: '',
      userRoleMasterId: 2,
      projectMasterId: props.projectMasterId as number
    }]
    if (token !== undefined) {
      const response = await fetch(
        `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${username}')&$count=true&$top=100&$select=onPremisesSamAccountName,displayName,id,mail`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      rawUsers = (await response.json()).value

      const users = rawUsers.map((rawUser) => ({
        name: rawUser.displayName,
        id: rawUser.id,
        systemId: rawUser.onPremisesSamAccountName !== '' ? rawUser.onPremisesSamAccountName : 'null',
        mail: rawUser.mail !== '' ? rawUser.mail : 'null',
        userRoleMasterId: 2,
        projectMasterId: props.projectMasterId
      }))
      return users
    }
    setShowUnableToAddUsersToast(true)
  }

  return (
      <Paper variant="outlined" style={{ padding: '20px', height: '80%', position: 'relative' }} className="mt-5">
        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
          <Typography fontSize={18} sx={{ fontWeight: '500' }}>{props.projectName}</Typography>
          </Link>
          <Typography fontSize={18} sx={{ fontWeight: '500' }} color="text.primary">Access Management</Typography>
        </Breadcrumbs>
        {/* {selectedUser !== undefined ? selectedUser.name !== undefined ? selectedUser.name : searchQuery : searchQuery */}
        <Box mb={3} m={10} sx={{ textAlign: 'center', width: '45%', margin: 'auto', marginTop: '5%' }}>
          <InputLabel className='mb-3' sx={{ textAlign: 'left' }}>
            Search & Add User
          </InputLabel>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'left'
          }} mb={1}>
            <TextField
              size="small"
              variant="outlined"
              value={searchQuery}
              sx={{ width: '68%', textAlign: 'left' }}
              onChange={handleUserNameInput}
              onKeyDown={handleUserInputBackspace}
            />
            <Button
              size="large"
              variant="contained"
              color="primary"
              disabled={selectedUser === undefined}
              style={{ height: '40px', width: '30%', marginLeft: '10px' }}
              onClick={handleAddUsers}
            >
              Add User
            </Button>
          </Box>
          <List disablePadding sx={{ textAlign: 'left', position: 'absolute', zIndex: 3, background: '#ebedec', maxHeight: '200px', overflow: 'scroll' }}>
            {searchedUsers.map(searchedUser => (
              <ListItem key={searchedUser.id} value={searchedUser.name} disablePadding sx={{ width: '24rem' }}><ListItemButton onClick={() => { handleUserSelect(searchedUser) }}>{searchedUser.name}</ListItemButton></ListItem>
            ))}
          </List>
         {isLoading
           ? <Box display={'flex'} alignItems={'center'} justifyContent={'center'} paddingTop={'100px'}>
            <CircularProgress />
          </Box>
           : (assignedUsers.length > 0
               ? <AssignedUsersTable assignedUsers={assignedUsers} deleteAssignedUser={deleteAssignedUser} />
               : <Typography sx={{ mt: '20px', color: 'grey' }}>No Users assigned</Typography>)
          }
        </Box>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={showAddUsersSuccessToast} onClose={() => { setShowAddUsersSuccessToast(false) }} autoHideDuration={6000} >
          <Alert sx={{ width: '100%' }} variant="filled" severity="success"> <AlertTitle>Success</AlertTitle>Added Users Successfully</Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={showNoUsersErrorToast} onClose={() => { setShowNoUsersErrorToast(false) }} autoHideDuration={6000} >
          <Alert sx={{ width: '100%' }} variant="filled" severity="error"> <AlertTitle>Invalid</AlertTitle>Please Add Users</Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={showUnableToAddUsersToast} onClose={() => { setShowUnableToAddUsersToast(false) }} autoHideDuration={6000} >
          <Alert sx={{ width: '100%' }} variant="filled" severity="error"> <AlertTitle>Error</AlertTitle>Unable To Add Users</Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={showUnableToDeleteUsersToast} onClose={() => { setShowUnableToDeleteUsersToast(false) }} autoHideDuration={6000} >
          <Alert sx={{ width: '100%' }} variant="filled" severity="error"> <AlertTitle>Error</AlertTitle>Unable To Delete User</Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={showDeleteUsersToast} onClose={() => { setDeleteUsersToast(false) }} autoHideDuration={6000} >
          <Alert sx={{ width: '100%' }} variant="filled" severity="success"> <AlertTitle>Success</AlertTitle>Deleted User Successfully</Alert>
        </Snackbar>
    </Paper>
  )
}
