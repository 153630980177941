import { Box, CircularProgress, Container } from '@mui/material'
import React, { useEffect, type ReactElement } from 'react'
import { DownloadFileBox } from '../components/DownloadFileBox'
import { useParams } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
// import { SnackbarContext } from '../hooks/snackContext'
import axios from 'axios'

export const DownloadFilePage = (): ReactElement<React.FC> => {
  const { id } = useParams()
  const { instance, accounts } = useMsal()
  const [isLoading, setIsLoading] = React.useState(false)
  // const { setSnackMessage } = React.useContext(SnackbarContext)

  useEffect(() => {
    console.log(id)
  })

  const getAccessToken = async (scope: string): Promise<string | undefined> => {
    if (accounts.length > 0) {
      const request = {
        scopes: [`${scope}`],
        account: accounts[0]
      }
      try {
        const response = await instance.acquireTokenSilent(request)
        return response.accessToken
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request)
          return response.accessToken
        }
      }
    }
  }

  const downloadFile = async (): Promise<void> => {
    setIsLoading(true)
    const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
    const apiUrl = process.env.REACT_APP_API_URL as string
    if (token !== undefined && id !== undefined) {
      const config = {
        method: 'get',
        url: `${apiUrl}/project/download/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
      const response = await axios(config)
      console.log(response.data)
      setIsLoading(false)
      const blob = new Blob([response.data], { type: 'application/octet-stream' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `project_${id}.csv`
      link.click()
    }
  }

  if (isLoading) {
    return <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} minHeight={'65vh'}>
      <CircularProgress />
      Downloading file...
    </Box>
  }

  return (
    <Container maxWidth={false} sx={{ marginTop: '2rem' }}>
      <DownloadFileBox downloadFile={downloadFile} text="Labelling has completed and file is available for download" />
    </Container>
  )
}
