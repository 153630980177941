import React, { type ReactElement, useState, useEffect } from 'react'
import { Container, Paper, Box, Typography, TextField, Button, Divider, InputAdornment, CircularProgress, Fade } from '@mui/material'
import Search from '@mui/icons-material/Search'
import { ProjectsTable } from '../components/ProjectsTable'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useMsal } from '@azure/msal-react'
import { InteractionRequiredAuthError } from '@azure/msal-browser'

interface ProjectAPIResponse {
  ProjectMasterId: number
  ProjectName: string
  CreatedDate: string
  UserRoleMasterId: string
  ProjectStatusMasterId: number
  actions: string
}
interface Project {
  ProjectMasterId: number
  ProjectName: string
  CreatedDate: string
  userRole: string
  status: string
  actions: string
  badgeColor: 'success' | 'info' | 'warning'
}

export const ProjectsPage = (): ReactElement<React.FC> => {
  const navigate = useNavigate()
  const { instance, accounts } = useMsal()
  const [searchQuery, setSearchQuery] = useState('')
  const [rows, setRows] = useState<Project[]>([])
  const [filteredRows, filterRows] = useState<Project[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [showProjects, setShowProjects] = useState(false)

  const handleCreateProject = (): void => {
    navigate('/project/create')
  }

  const handleSearchQueryInput = async (e: { target: { value: string } }): Promise<void> => {
    if (e.target.value === '') {
      setSearchQuery(e.target.value)
      filterRows(rows)
    } else {
      console.log(e.target.value)
      setSearchQuery(e.target.value)
      const result = rows.filter((row) => {
        const keys = Object.keys(row)
        const keyToRemove = 'badgeColor'
        const indexToRemove = keys.indexOf(keyToRemove)
        keys.splice(indexToRemove, 1)
        return keys.some(key => row[key as keyof typeof row].toString().toLowerCase().search(e.target.value.toLowerCase()) !== -1)
      })
      filterRows([...result])
    }
  }

  const setProjectStatus = (ProjectStatusMasterId: number): { status: string, badgeColor: 'success' | 'info' | 'warning' } => {
    if (ProjectStatusMasterId === 2) {
      return {
        status: 'In Progress',
        badgeColor: 'info'
      }
    }
    if (ProjectStatusMasterId === 3) {
      return {
        status: 'Completed',
        badgeColor: 'success'
      }
    }
    if (ProjectStatusMasterId === 4) {
      return {
        status: 'Pre-processing',
        badgeColor: 'info'
      }
    }
    if (ProjectStatusMasterId === 5) {
      return {
        status: 'Labeling In Progress',
        badgeColor: 'info'
      }
    }
    if (ProjectStatusMasterId === 6) {
      return {
        status: 'Labeling Complete',
        badgeColor: 'info'
      }
    }
    if (ProjectStatusMasterId === 7) {
      return {
        status: 'Review Complete',
        badgeColor: 'success'
      }
    }
    if (ProjectStatusMasterId === 8) {
      return {
        status: 'Execution Failed',
        badgeColor: 'info'
      }
    }
    return {
      status: 'Created',
      badgeColor: 'warning'
    }
  }
  const createRows = (rows: ProjectAPIResponse[]): Project[] => {
    const projects = rows.map((row: ProjectAPIResponse) => ({
      ProjectMasterId: row.ProjectMasterId,
      ProjectName: row.ProjectName,
      CreatedDate: new Date(row.CreatedDate).toLocaleDateString('en-US'),
      userRole: row.UserRoleMasterId.toString() === '1' ? 'Admin' : 'Analyst',
      status: setProjectStatus(row.ProjectStatusMasterId).status,
      actions: getNumberOfRetentionDays(row),
      badgeColor: setProjectStatus(row.ProjectStatusMasterId).badgeColor
    }))
    return projects

    function getNumberOfRetentionDays (row: ProjectAPIResponse): string {
      const date1 = new Date(row.CreatedDate)
      const date2 = new Date()
      const differenceInTime = date2.getTime() - date1.getTime()
      const differenceInDays = differenceInTime / (1000 * 3600 * 24)
      const remainingDays = 30 - differenceInDays
      const remainingDaysWithoutDecimalsInInt = Math.trunc(remainingDays)
      return row.ProjectStatusMasterId === 7 ? `Retention: ${remainingDaysWithoutDecimalsInInt} days` : '---'
    }
  }

  const getProjects = async (): Promise<void> => {
    try {
      let userId = ''
      if (accounts[0] !== undefined) {
        // eslint-disable-nextline  @typescript-eslint/no-non-null-assertion
        userId = accounts[0]?.idTokenClaims?.uid as string
      }
      const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
      const apiUrl = process.env.REACT_APP_API_URL as string
      if (token !== undefined && userId !== undefined) {
        const config = {
          method: 'get',
          url: `${apiUrl}/projects/?userId=${userId}`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
        const response: any = await axios(config)
        console.log('Data', response.data.data.rows)
        const projects = createRows(response.data.data.rows)
        if (projects.length > 0) {
          setShowProjects(true)
          setRows(projects)
          setIsLoading(false)
          filterRows(projects)
        }
        setIsLoading(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect((): void => {
    // eslint-disable-next-line
    (async (): Promise<void> => {
      await getProjects()
    })()
  }, [])

  const getAccessToken = async (scope: string): Promise<string | undefined> => {
    if (accounts.length > 0) {
      const request = {
        scopes: [`${scope}`],
        account: accounts[0]
      }
      try {
        const response = await instance.acquireTokenSilent(request)
        return response.accessToken
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request)
          return response.accessToken
        }
      };
    }
  }

  return (
    <Container maxWidth={false} sx={{ marginTop: '2rem' }}>
      {!isLoading && <Box
        flexDirection={'column'}
        minWidth={'100%'}
        alignItems={'center'}
        justifyContent={'center'}
        style={{ display: !showProjects ? 'flex' : 'none', marginTop: '15%' }}
      >
        <Typography variant="h3" color={'gray'} sx={{ marginBottom: '30px' }}>
          No Data Available!
        </Typography>
        <Button
          onClick={handleCreateProject}
          variant="contained"
          sx={{ my: '30' }}
          size="large"
        >
          Create New Project
        </Button>
      </Box>}
      {!isLoading && <Fade in mountOnEnter unmountOnExit><Paper sx={{ display: showProjects ? 'block' : 'none' }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%'
        }}>
          <Typography
            variant="h5"
            component="h1"
            sx={{ width: '49%', fontWeight: '500', padding: '1rem' }}
          >
            All Projects
          </Typography>
          <Box sx={{
            width: '50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right'
          }}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                )
              }}
              size="small"
              variant="outlined"
              value={searchQuery}
              placeholder='Search...'
              sx={{ width: '40%', padding: '1rem' }}
              onChange={handleSearchQueryInput}
            />
            <Button
              size="small"
              variant="contained"
              color="primary"
              style={{ marginTop: '14px', height: '40px' }}
              onClick={() => { navigate('/project/create') }}
            >
              Create Project
            </Button>
          </Box>
        </Box>
        <Divider />
        <ProjectsTable rows={filteredRows} />
      </Paper></Fade>}
      {isLoading && <Box display={'flex'} alignItems={'center'} justifyContent={'center'} minHeight={'80vh'}>
        <CircularProgress />
      </Box>
      }
    </Container>
  )
}
