import * as React from 'react'
import { Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

export const Infom = (props: { info: string }): React.ReactElement<React.FC> => {
  return (
    <Tooltip title={props.info}>
        <InfoOutlined sx={{ cursor: 'pointer' }} fontSize='small' color='primary' />
    </Tooltip>
  )
}
