import React, { type ReactElement } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { Layout } from '../components/Layout'
import { ProjectsPage } from '../pages/ProjectsPage'
import { HelpPage } from '../pages/HelpPage'
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { CreateProjectPage } from '../pages/CreateProjectPage'
import { ProjectWizardPage } from '../pages/ProjectWizardPage'
import { ReviewPage } from '../pages/ReviewPage'
import { SnackbarContext } from '../hooks/snackContext'
import { Alert, AlertTitle, Snackbar } from '@mui/material'
import { DownloadFilePage } from '../pages/DownloadPage'

function App (): ReactElement<React.FC> {
  const [snackMessage, setSnackMessage] = React.useState('')
  const [type, setType] = React.useState('')
  const request = {
    scopes: [process.env.REACT_APP_API_SCOPE as string, 'User.Read']
  }
  useMsalAuthentication(InteractionType.Redirect, request)
  return (
    <SnackbarContext.Provider value={{ snackMessage, setSnackMessage, type, setType }}>
    <div className="App">
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackMessage !== ''} onClose={() => { setSnackMessage('') }} autoHideDuration={5000} >
        <Alert sx={{ width: '100%' }} variant="filled" severity={ type === 'error' ? 'error' : 'success' }> <AlertTitle>{ type === 'error' ? 'Error' : 'Success' }</AlertTitle>{snackMessage}</Alert>
      </Snackbar>
      <BrowserRouter>
        {/* add all authenticated routes below */}
        <AuthenticatedTemplate>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<ProjectsPage />} />
              <Route path="/help" element={<HelpPage />} />
              <Route path="/download/:id" element={<DownloadFilePage />} />
              <Route path="/project/:id" element={<ProjectWizardPage />} />
              <Route path="/project/create" element={<CreateProjectPage />} />
              <Route path="/projects" element={<ProjectsPage />} />
              <Route path="/project/:id/review/" element={<ReviewPage />} />
            </Route>
          </Routes>
        </AuthenticatedTemplate>
      </BrowserRouter>
    </div>
    </SnackbarContext.Provider>
  )
}

export default App
