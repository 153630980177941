// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from 'react'
import { DataGrid, type GridRowId, type GridColDef, type GridRenderCellParams, type GridValueFormatterParams, type GridCellModesModel, GridCellModes, type GridCellParams } from '@mui/x-data-grid'
import { Box, Breadcrumbs, Button, Chip, CircularProgress, Divider, Link, Paper, Popper, Stack, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { ArrowDropDownOutlined, NavigateNext } from '@mui/icons-material'
import { useMsal } from '@azure/msal-react'
import axios from 'axios'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import Papa from 'papaparse'
import debounce from 'lodash.debounce'
import { json2csv } from 'json-2-csv'
import { LoadingButton2 } from './LoadingButton'
import { useNavigate } from 'react-router-dom'

interface GridCellExpandProps {
  value: string
  width: number
}

interface Row {
  clean_text: string
  confidence_score: number
  estimated_label: number
  id?: number
  review_required?: string
}

function isOverflown (element: Element): boolean {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  )
}

const GridCellExpand = React.memo(function GridCellExpand (
  props: GridCellExpandProps
) {
  const { width, value } = props
  const wrapper = React.useRef<HTMLDivElement | null>(null)
  const cellDiv = React.useRef(null)
  const cellValue = React.useRef(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [showFullCell, setShowFullCell] = React.useState(false)
  const [showPopper, setShowPopper] = React.useState(false)

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleMouseEnter = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const isCurrentlyOverflown = isOverflown(cellValue.current!)
    setShowPopper(isCurrentlyOverflown)
    setAnchorEl(cellDiv.current)
    setShowFullCell(true)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleMouseLeave = () => {
    setShowFullCell(false)
  }

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function handleKeyDown (nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [setShowFullCell, showFullCell])

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        backgroundColor: 'white'
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width,
          display: 'block',
          position: 'absolute',
          top: 0
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            style={{ minHeight: wrapper.current!.offsetHeight - 3, backgroundColor: grey[800] }}
          >
            <Typography variant='body2' color={grey[200]} style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  )
})

export const ProcessSummaryTable = ({ projectMasterId, projectName }: { projectMasterId: string, projectName: string }): React.ReactElement<React.FC> => {
  const [approveState, setApproveStatee] = React.useState<any>([])
  const [filterThreshold, setFilterThreshold] = React.useState<number>(80)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isSavingFile, setIsSavingFile] = React.useState<boolean>(false)
  const [rows, setRows] = React.useState<any>([])
  const [filteredRows, setFilteredRows] = React.useState<any>([])
  const [labels, setLabels] = React.useState<any>([])
  const [timeoutId, setTimeoutId] = React.useState<null | NodeJS.Timeout>(null)
  const [loadingButtonStates, setLoadingButtonStates] = React.useState([])
  const [loadingButtonTitles, setLoadingButtonTitles] = React.useState([])
  const [pageOptions, setPageOptions] = React.useState({ page: 0, pageSize: 10 })
  const [selectedRowsCount, setSelectedRowsCount] = React.useState<number>(0)
  const [reviewedRows, setReviewedRows] = React.useState([])
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({})
  const { instance, accounts } = useMsal()
  const navigate = useNavigate()

  const columns: GridColDef[] = [
    { field: 'clean_text', headerName: 'Clean Text', width: 300, flex: 1, renderCell: renderCellExpand },
    {
      field: 'confidence_score',
      headerName: 'Confidence score',
      width: 130,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return ''
        }
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `${(params.value * 100).toFixed()} %`
      }
    },
    {
      field: 'estimated_label',
      headerName: 'Estimated Label',
      editable: true,
      type: 'singleSelect',
      valueOptions: labels,
      renderCell: ({ value }) => (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div>{value}</div>
          <ArrowDropDownOutlined />
        </Box>
      ),
      width: 200
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Action',
      width: 180,
      cellClassName: 'actions',
      getActions: (data) => {
        return [
          <LoadingButton2 title={ loadingButtonTitles[data.id] !== undefined ? loadingButtonTitles[data.id] : 'Confirm' } id={data.id.toString()} key={data.id} disabled={isSavingFile} loading={loadingButtonStates[data.id]} size='small' handler={debouncedOnCompleteReviewClick} />
        ]
      }
    }
  ]
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function renderCellExpand (params: GridRenderCellParams<any, string>) {
    return (
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/prefer-nullish-coalescing
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    )
  }

  React.useEffect((): void => {
    // eslint-disable-next-line
    (async (): Promise<void> => {
      await getThreshold()
      await getMLLabelledCsvFile()
    })()
  }, [])

  const getAccessToken = async (scope: string): Promise<string | undefined> => {
    if (accounts.length > 0) {
      const request = {
        scopes: [`${scope}`],
        account: accounts[0]
      }
      try {
        const response = await instance.acquireTokenSilent(request)
        return response.accessToken
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request)
          return response.accessToken
        }
      }
    }
  }

  const getThreshold = async (): Promise<any> => {
    setIsLoading(true)
    const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
    const apiUrl = process.env.REACT_APP_API_URL as string
    if (token !== undefined) {
      const config = {
        method: 'get',
        url: `${apiUrl}/project/${projectMasterId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      }
      const response = await axios(config)
      setIsLoading(false)

      if (response.status === 200) {
        const data: any[] = response.data.data
        const unlabelled = data.filter((item) => item.ProjectFileMasterId === 2)
        console.log('Unlabelled Data ::: ', unlabelled[0].ProjectThreshold)
        if (unlabelled.length > 0) {
          setFilterThreshold(unlabelled[0].ProjectThreshold)
        }
      }
    }
  }

  const getMLLabelledCsvFile = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
      const apiUrl = process.env.REACT_APP_API_URL as string
      if (token !== undefined) {
        const config = {
          method: 'get',
          url: `${apiUrl}/project/${projectMasterId}/review/estimatedLabels.csv`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
        const response = await axios(config)
        // Use Papa Parse to convert CSV data to JSON
        Papa.parse(response.data, {
          skipEmptyLines: true,
          header: true, // Assuming the first row contains headers
          dynamicTyping: true, // Automatically parse numbers and booleans
          complete: (result) => {
            if (result.errors.length === 0) {
              const payload = result.data as Row[]
              // add id to each row
              const data = payload.map((row: Row, index) => ({ ...row, id: index, review_required: row.review_required !== undefined ? row.review_required : ((row.confidence_score * 100) > filterThreshold ? 'not_required' : 'required') }))
              // get unique labels from estimated_label column
              setLoadingButtonStates(data.map(() => false))
              const allLabels = new Set(data.map((item: any) => item.estimated_label))
              const uniqueLabels = Array.from(allLabels)
              setLabels(uniqueLabels)
              setRows(data)
              console.log('data', data)
              setLoadingButtonTitles([])
              setSelectedRowsCount(0)
              setReviewedRows([])
              const filteredData = data.filter((row: any) => row.confidence_score < filterThreshold / 100 && row.review_required === 'required')
              setFilteredRows(filteredData)
            } else {
              console.error('CSV parsing error:', result.errors)
            }
            setIsLoading(false)
          }
        })
        setIsLoading(false)
        return
      }
      throw new Error('getUserRole: missing parameters')
    } catch (error) {
      console.log(error)
    }
  }

  const handleCellClick = React.useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (params.isEditable === false) {
        return
      }

      // Ignore portal
      if (!event.currentTarget.contains(event.target as Element)) {
        return
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View }
                }),
                {}
              )
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit }
          }
        }
      })
    },
    []
  )

  const handleCellModesModelChange = React.useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel)
    },
    []
  )

  const handleChange = (e: any): void => {
    setFilterThreshold(e.target.value.replace(/\D/g, ''))
    if (e.target.value.toString() === '100' || e.target.value.toString().length < 3) {
      setFilteredRows(rows.filter((row: any) => row.confidence_score < e.target.value.replace(/\D/g, '') / 100 && row.review_required === 'required'))
    }
  }

  const debouncedOnChange = debounce(handleChange, 1000)

  const getTotalRowsLength = (): number => {
    return rows.length
  }
  const getToReviewRowLength = (): number => {
    // check rows with confidence score less than filterThreshold and check if review_required is not 'required'
    return filteredRows.filter((row: any) => row.review_required === 'required').length
  }

  const getSelectedRows = (ids: number[]): any => {
    console.log('id', ids)
    let selectedRows = ids.map(id => rows[id])
    selectedRows = selectedRows.map(row => ({ ...row, review_required: 'complete' }))
    return selectedRows
  }

  const getCancelledRows = (ids: number[]): any => {
    console.log('id', ids)
    let cancelledRows = ids.map(id => rows[id])
    cancelledRows = cancelledRows.map(row => ({ ...row, review_required: 'required' }))
    return cancelledRows
  }

  const getUpdateRows = (selectedRows: Row[]): Row[] => {
    const result = Array.from([...rows, ...selectedRows]
      .reduce((m, o) => m.set(o.id, o), new Map())
      .values()
    )
    return result
  }

  const onCompleteReviewClick = async (event: { target: { id: string } }): Promise<void> => {
    let updateRows
    console.log('clicked onCompleteReviewClick', [event.target.id])
    if (event.target.id !== '' && loadingButtonTitles[event.target.id] === 'Cancel') {
      const updatedLoadingButtonTiles = [...loadingButtonTitles]
      updatedLoadingButtonTiles[event.target.id] = 'Confirm'
      setLoadingButtonTitles(updatedLoadingButtonTiles)
      console.log('Cancel button clicked')
      const cancelledRows = getCancelledRows([event.target.id])
      updateRows = getUpdateRows(cancelledRows)
      setRows(updateRows)
      setSelectedRowsCount(selectedRowsCount - 1)
      let updatedReviewedRows = [...reviewedRows]
      updatedReviewedRows = updatedReviewedRows.filter(row => row.id !== parseInt(event.target.id))
      console.log('Cancel: updatedReviewedRows', updatedReviewedRows)
      setReviewedRows(updatedReviewedRows)
    } else {
      const ids = [event.target.id]
      const updatedLoadingButtonTiles = [...loadingButtonTitles]
      updatedLoadingButtonTiles[event.target.id] = 'Cancel'
      setLoadingButtonTitles(updatedLoadingButtonTiles)

      const selectedRows = getSelectedRows(ids)
      const updateRows = getUpdateRows(selectedRows)
      setRows(updateRows)
      setSelectedRowsCount(selectedRowsCount + 1)
      const updatedReviewedRows = [...reviewedRows]
      updatedReviewedRows.push(rows[event.target.id])
      setReviewedRows(updatedReviewedRows)
    }
  }

  React.useEffect(() => {
    if (timeoutId != null) {
      clearTimeout(timeoutId)
    }
    const newTimeoutId = setTimeout(async () => {
      // set loading button
      const updatedLoadingButtonStates = [...loadingButtonStates]
      for (let i = 0; i < reviewedRows.length; i += 1) {
        updatedLoadingButtonStates[reviewedRows[i].id] = true
      }
      setLoadingButtonStates(updatedLoadingButtonStates)
      // Call saveReviewedFile function
      console.log('Calling API with rows:', rows)
      const file = await generateCSV(rows)
      await saveReviewedFile(file)
    }, 10000)

    setTimeoutId(newTimeoutId)
  }, [selectedRowsCount, rows, reviewedRows, loadingButtonStates])

  const onCompleteReviewMultiSelectClick = async (): Promise<void> => {
    setIsSavingFile(true)
    const selectedRows = getSelectedRows(approveState)
    const result = Array.from([...rows, ...selectedRows]
      .reduce((m, o) => m.set(o.id, o), new Map())
      .values()
    )
    const data = await generateCSV(result)
    const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
    const apiUrl = process.env.REACT_APP_API_URL as string
    const blob = new Blob([data], { type: 'text/csv' })
    const file = new File([blob], 'estimatedLabels.csv', { type: 'text/csv' })
    const formData = new FormData()
    formData.append('csv', file)
    if (token !== undefined) {
      const config = {
        method: 'post',
        url: `${apiUrl}/project/${projectMasterId}/review`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      }
      const response = await axios(config)
      console.log('saveReviewedFile Response', response.data)
      await getMLLabelledCsvFile()
    }
    setIsSavingFile(false)
  }

  React.useEffect(() => {
    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId)
      }
    }
  }, [timeoutId])

  const debouncedOnCompleteReviewClick = debounce(onCompleteReviewClick, 10)
  const debouncedOnCompleteReviewMultiSelectClick = onCompleteReviewMultiSelectClick

  const generateCSV = async (data: Rows[]): Promise<string> => {
    const csv = await json2csv(data)
    return csv
  }

  const saveReviewedFile = async (data: any): Promise<void> => {
    const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
    const apiUrl = process.env.REACT_APP_API_URL as string
    const blob = new Blob([data], { type: 'text/csv' })
    const file = new File([blob], 'estimatedLabels.csv', { type: 'text/csv' })
    const formData = new FormData()
    formData.append('csv', file)
    if (token !== undefined) {
      const config = {
        method: 'post',
        url: `${apiUrl}/project/${projectMasterId}/review`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      }
      if (selectedRowsCount > 0) {
        setIsSavingFile(true)
        console.log('calling save API', selectedRowsCount)
        console.log('reviewed rows', reviewedRows)
        setReviewedRows([])
        setSelectedRowsCount(0)
        const response = await axios(config)
        console.log('saveReviewedFile Response', response.data)
        await getMLLabelledCsvFile()
        setIsSavingFile(false)
      } else {
        console.log('No rows to save using single confirm button', selectedRowsCount)
      }
    }
  }

  const onCompleteManualReview = async (): Promise<void> => {
    const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
    const apiUrl = process.env.REACT_APP_API_URL as string
    const data = await generateCSV(rows)
    const blob = new Blob([data], { type: 'text/csv' })
    const file = new File([blob], 'completeReview.csv', { type: 'text/csv' })
    const formData = new FormData()
    formData.append('csv', file)
    if (token !== undefined) {
      const config = {
        method: 'post',
        url: `${apiUrl}/project/${projectMasterId}/complete-review`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      }
      const response = await axios(config)
      console.log(response.data)
      navigate(`/download/${projectMasterId}`)
    }
  }

  const handleProcessRowUpdate = (updatedRow: Row): Row => {
    // Find the index of the row that was edited
    updatedRow.review_required = 'complete'
    const rowIndex = rows.findIndex((row: any) => row.id === updatedRow.id)
    const updatedRows = rows
    updatedRows[rowIndex] = updatedRow
    setRows(updatedRows)
    return updatedRow
  }
  if (isLoading && rows.length === 0) {
    return <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} minHeight={'65vh'}>
      <CircularProgress />
      Fetching file in progress...
    </Box>
  }

  // if (isSavingFile) {
  //   return <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} minHeight={'65vh'}>
  //     <CircularProgress />
  //     Saving labels.....
  //   </Box>
  // }
  return (
    <Paper elevation={0} sx={{ p: 1 }} >
      <Breadcrumbs separator={<NavigateNext fontSize="small" />} sx={{ mb: 1, ml: 2, mt: 2 }} aria-label="breadcrumb">
        <Typography fontSize={20} sx={{ fontWeight: '500' }}>
          <Link underline="hover" color="inherit" href="/">
            {projectName}</Link></Typography>
        <Typography fontSize={20} sx={{ fontWeight: '500' }} color="text.primary">Process summary</Typography>
      </Breadcrumbs>
      <Stack alignItems={'center'} direction="row" spacing={2} sx={{ p: 2 }} >
        <Typography fontWeight={'bold'} fontSize={15} color={'#616161'} component="div" >Above confidence score of</Typography>
        <TextField
          id="outlined-number"
          sx={{ textAlign: 'center', padding: '0px', width: '40px' }}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', type: 'text', style: { paddingRight: 5, paddingLeft: 4, textAlign: 'center' } }}
          variant="outlined"
          onChange={debouncedOnChange}
          defaultValue={filterThreshold}
          size='small'
        /><Typography fontWeight={'bold'} fontSize={15} color={'#616161'} component="div" >%</Typography>
        <Chip color='secondary' label={<Typography fontSize={13}>To review <strong>{getToReviewRowLength()}</strong> out of <strong>{getTotalRowsLength()}</strong></Typography>} variant="outlined" />
        <Chip color='secondary' label={<Typography fontSize={13}>Processed <strong>{getTotalRowsLength() - getToReviewRowLength()}</strong> out of <strong>{getTotalRowsLength()}</strong></Typography>} variant="outlined" />
      </Stack>
      <Box display={'flex'} flexDirection={'row'} style={{ marginBottom: '8px' }}>
        {getToReviewRowLength() !== 0 && <LoadingButton2
        title={'Confirm Selected'}
        id={'confirmbutton-1'}
        disabled={approveState.length === 0}
        loading={isSavingFile}
        size='small'
        handler={debouncedOnCompleteReviewMultiSelectClick} />}
        {getToReviewRowLength() === 0 && <Button
          variant='contained'
          sx={{
            mx: 1
          }}
          disabled={getToReviewRowLength() !== 0}
          onClick={onCompleteManualReview}
        >
          Complete review
        </Button>}
      </Box>
      <Divider />
      <Typography fontWeight={'bold'} fontSize={15} color={'#616161'} component="div" sx={{ flexGrow: 1, p: 2 }}>Below confidence score</Typography>
      <DataGrid
        localeText={{ noRowsLabel: `No results below the confidence score of ${filterThreshold}%` }}
        sx={{ m: 1 }}
        rows={filteredRows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: pageOptions
          }
        }}
        onPaginationModelChange={(e) => {
          console.log('pagination model changes', e)
          setPageOptions(e)
        }}
        pageSizeOptions={[10, 20]}
        checkboxSelection
        autoHeight
        disableRowSelectionOnClick
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        processRowUpdate={handleProcessRowUpdate}
        onRowSelectionModelChange={(newSelectionModel: GridRowId[]) => {
          setApproveStatee(newSelectionModel)
        }}
      />
      <Box display={'flex'} flexDirection={'row'}>
        {getToReviewRowLength() !== 0 && <LoadingButton2
        title={'Confirm Selected'}
        id={'confirmbutton-1'}
        disabled={approveState.length === 0}
        loading={isSavingFile}
        size='small'
        handler={debouncedOnCompleteReviewMultiSelectClick} />}
        {getToReviewRowLength() === 0 && <Button
          variant='contained'
          sx={{
            mx: 1
          }}
          disabled={getToReviewRowLength() !== 0}
          onClick={onCompleteManualReview}
        >
          Complete review
        </Button>}
      </Box>
    </Paper>
  )
}
