import React, { type ReactElement } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import CancelIcon from '@mui/icons-material/CancelOutlined'

interface User {
  name: string
  id: string
  systemId: string
}

interface Props {
  assignedUsers: User[]
  deleteAssignedUser: (id: string) => void
}

export const AssignedUsersTable = (props: Props): ReactElement<React.FC> => {
  return (
    <TableContainer sx={{ position: 'relative', zIndex: 2, maxHeight: '16rem' }} component={Paper}>
      <Table size="small" aria-label="assigned-users-table">
        <TableHead>
          <TableRow>
            <TableCell>Serial No.</TableCell>
            <TableCell align="center">User</TableCell>
            <TableCell align="center">Role</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.assignedUsers.map((assignedUser, index) => {
            return (
              <TableRow
                key={assignedUser.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="center">{assignedUser.name + ` (${assignedUser.systemId})`}</TableCell>
                <TableCell align="center">{'Analyst'}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => { props.deleteAssignedUser(assignedUser.id.toString()) }} aria-label="delete" size="small">
                    <CancelIcon fontSize="inherit" />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer >
  )
}
