import { Box, Button, Container, Paper, Typography } from '@mui/material'
import React, { type ReactElement } from 'react'
import { grey } from '@mui/material/colors'
import { Download } from '@mui/icons-material'

export const DownloadFileBox = ({ text, downloadFile }: { text: string, downloadFile: any }): ReactElement<React.FC> => {
  return (
        <Box
          display={'flex'}
          height={'90%'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Container>
            <Paper sx={{ padding: 10, textAlign: 'center' }}>
            {/* <Typography color={grey[700]} fontSize={18} fontWeight={'bold'}>
                File is not available for download yet
              </Typography> */}
              <Typography color={grey[700]} fontSize={18} fontWeight={'bold'}>
                {text}
              </Typography>
              <Button onClick={downloadFile} startIcon={<Download />} sx={{ mt: 4, mb: 2 }} variant='contained'>
                Download *
              </Button>
              <Typography color={grey[500]} fontSize={13} fontWeight={'bold'} >
                * File will be retained for 30 days from the date of labelling completion
              </Typography>
            </Paper>
          </Container>
        </Box>)
}
