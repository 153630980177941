/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Link, Alert, AlertTitle, Box, Breadcrumbs, Button, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, List, ListItem, ListItemText, MenuItem, Paper, Select, Slide, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { type ReactElement, useCallback, useState, useMemo, useEffect } from 'react'
import axios from 'axios'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { NavigateNext } from '@mui/icons-material'
import { useProjectStore } from '../hooks/projectwizardStore'

export const ReviewConfig = (props: { projectMasterId: number, projectName: string | undefined }): ReactElement<React.FC> => {
  const { instance, accounts } = useMsal()
  const [isLoading, setIsLoading] = useState(false)
  const [responseData, setResponseData] = useState<any[]>([])
  const [labelledData, setLabelledData] = useState<any>()
  const [unlabelledData, setUnlabelledData] = useState<any>()
  const { previousStack } = useProjectStore((state) => state)
  const navigate = useNavigate()

  useEffect(() => {
    void getProjectDetails()
  }, [])

  const getAccessToken = async (scope: string): Promise<string | undefined> => {
    if (accounts.length > 0) {
      const request = {
        scopes: [`${scope}`],
        account: accounts[0]
      }
      try {
        const response = await instance.acquireTokenSilent(request)
        return response.accessToken
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request)
          return response.accessToken
        }
      };
    }
  }

  const getProjectDetails = async (): Promise<any> => {
    setIsLoading(true)
    const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
    const apiUrl = process.env.REACT_APP_API_URL as string
    if (token !== undefined) {
      const config = {
        method: 'get',
        url: `${apiUrl}/project/${props.projectMasterId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      }
      const response = await axios(config)
      setIsLoading(false)

      if (response.status === 200) {
        const data: any[] = response.data.data
        if (data.length >= 2) {
          setResponseData(data)
        }
        const unlabelled = data.filter((item) => item.ProjectFileMasterId === 2)
        const labelled = data.filter((item) => item.ProjectFileMasterId === 1)
        setLabelledData(labelled[0])
        setUnlabelledData(unlabelled[0])
      }
    }
  }

  const triggerPreprocessingAndMLModel = async (): Promise<void> => {
    const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
    const apiUrl = process.env.REACT_APP_API_URL as string
    if (token !== undefined) {
      setIsLoading(true)
      const config = {
        method: 'get',
        url: `${apiUrl}/label/${props.projectMasterId}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      const response = await axios(config)
      if (response.status === 200) {
        setIsLoading(false)
        navigate(`/project/${props.projectMasterId}/review`)
      } else {
        setIsLoading(false)
      }
    }
  }

  return <Paper variant="outlined" sx={{ padding: '10px', minHeight: '85%', mt: '20px' }}>
    <Breadcrumbs sx={{ p: 1 }} separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
      <Link underline="hover" color="inherit" href="/">
        <Typography fontSize={18} sx={{ fontWeight: '500' }}>{props.projectName}</Typography>
      </Link>
      <Typography fontSize={18} sx={{ fontWeight: '500' }} color="text.primary">Review Configurations</Typography>
    </Breadcrumbs>
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap'
        // height: '85%'
      }}
    >
      {!isLoading
        ? <Stack direction={'column'} justifyContent={'center'} sx={{ width: '100%', textAlign: 'center' }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ width: '100%' }}
          >
            <Paper variant="outlined" sx={{ width: '50%', p: 2 }} >
              <Typography sx={{ textDecoration: 'underline' }} fontWeight={'bold'} >Labelled data</Typography>
              <Box sx={{ width: '100%', pt: 1 }}>
                <List>
                  <ListItem>
                    <ListItemText primaryTypographyProps={{ fontSize: 16 }}
                      primary="Data columns"
                      secondaryTypographyProps={{ fontSize: 18, pl: 1 }}
                      secondary={labelledData?.ProjectDataColumns.split(',').map((text: string, idx: number) => <Chip key={idx} label={text} sx={{ mr: 0.5, mt: 0.5 }} />)} />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primaryTypographyProps={{ fontSize: 16 }}
                      primary="Labelled column"
                      secondaryTypographyProps={{ fontSize: 18, pl: 1 }}
                      secondary={<Chip label={labelledData?.ProjectLabelledColumn} sx={{ mr: 0.5, mt: 0.5 }} />} />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primaryTypographyProps={{ fontSize: 16 }}
                      primary="Delimeter"
                      secondaryTypographyProps={{ fontSize: 18, pl: 1 }}
                      secondary={labelledData?.ProjectDelimeter ? <Chip label={labelledData?.ProjectDelimeter} sx={{ mr: 0.5, mt: 0.5 }} /> : <Chip label={'comma'} sx={{ mr: 0.5, mt: 0.5 }} />} />
                  </ListItem>
                </List>
              </Box>
            </Paper>
            <Paper variant="outlined" sx={{ width: '50%', p: 2 }}>
              <Typography sx={{ textDecoration: 'underline' }} fontWeight={'bold'}>Unlabelled data</Typography>
              <Box sx={{ height: '90%', width: '100%' }}>
                <List>
                  <ListItem>
                    <ListItemText primaryTypographyProps={{ fontSize: 16 }}
                      primary="Data columns"
                      secondaryTypographyProps={{ fontSize: 18, pl: 1 }}
                      secondary={unlabelledData?.ProjectUnlabelledDataColumns.split(',').map((text: string, idx: number) => <Chip key={idx} label={text} sx={{ mr: 0.5, mt: 0.5 }} />)} />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primaryTypographyProps={{ fontSize: 16 }}
                      primary="Preprocessor"
                      secondaryTypographyProps={{ fontSize: 18, pl: 1 }}
                      secondary={unlabelledData?.ProjectPreProcessor.split(',').map((text: string, idx: number) => <Chip key={idx} label={text} sx={{ mr: 0.5, mt: 0.5 }} />)} />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primaryTypographyProps={{ fontSize: 16 }}
                      primary="Delimeter"
                      secondaryTypographyProps={{ fontSize: 18, pl: 1 }}
                      secondary={unlabelledData?.ProjectDelimeter ? <Chip label={unlabelledData?.ProjectDelimeter} sx={{ mr: 0.5, mt: 0.5 }} /> : <Chip label={'comma'} sx={{ mr: 0.5, mt: 0.5 }} />} />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primaryTypographyProps={{ fontSize: 16 }}
                      primary="Threshold"
                      secondaryTypographyProps={{ fontSize: 18, pl: 1 }}
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      secondary={unlabelledData?.ProjectThreshold && <Chip label={`${unlabelledData?.ProjectThreshold.toString()}%`} sx={{ mr: 0.5, mt: 0.5 }} />} />
                  </ListItem>
                </List>
              </Box>
            </Paper>
          </Stack>
          <Typography sx={{ py: 2, fontSize: 16 }}>Are you sure want to proceed with above configurations?</Typography>
          <Stack direction={'row'} justifyContent={'center'} sx={{ width: '100%', textAlign: 'center' }}>
            <Button onClick={previousStack} variant={'outlined'} sx={{ mx: 2, width: 100 }} size='large'>No</Button>
            <Button onClick={triggerPreprocessingAndMLModel} variant={'contained'} sx={{ mx: 2, width: 100 }} size='large'>Yes</Button>
          </Stack>
        </Stack>
        : <Box height={'80%'} display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'}><CircularProgress /></Box>}

    </Box>
  </Paper>
}
