/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import React, { useEffect, useState, type ReactElement } from 'react'
import { Box, Button, Container, Slide, CircularProgress, Typography } from '@mui/material'
import { CreatProjectForm } from '../components/CreateProjectForm'
import { useParams, useNavigate } from 'react-router-dom'
import { StepperComponent } from '../components/StepperComponent'
import { LabelledDataUpload } from '../components/LabelledDataUpload'
import { UnLabelledDataUpload } from '../components/UnLabelledData'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { useMsal } from '@azure/msal-react'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import axios from 'axios'
import { useProjectStore } from '../hooks/projectwizardStore'
import { ReviewConfig } from '../components/ReviewConfig'

// typescript type for project information
interface ProjectInformation {
  ProjectMasterId?: string
  ProjectStatusMasterId?: number
  UserGlobalId?: string
  UserRoleMasterId?: number
  ProjectName?: string
}

export const ProjectWizardPage = (): ReactElement<React.FC> => {
  const { instance, accounts } = useMsal()
  const { id } = useParams()
  // const [activeStack, setActiveStack] = useState(0)
  const [projectInformation, setProjectInformation] = useState<ProjectInformation>({ ProjectMasterId: '', ProjectStatusMasterId: 0, UserGlobalId: '', UserRoleMasterId: 0 })
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { nextStackEnabled, nextStack, previousStack, currentStack, resetCurrentStack } = useProjectStore((state) => state)
  const [projectName, setProjectName] = useState<string | undefined>('')

  useEffect((): void => {
    // eslint-disable-next-line
    (async (): Promise<void> => {
      await getProjectInformation()
    })()
  }, [])

  // const nextPage = (): void => {
  //   setActiveStack((prevActiveStep) => prevActiveStep + 1)
  // }

  // const previousPage = (): void => {
  //   setActiveStack((prevActiveStep) => prevActiveStep - 1)
  // }

  const getAccessToken = async (scope: string): Promise<string | undefined> => {
    if (accounts.length > 0) {
      const request = {
        scopes: [`${scope}`],
        account: accounts[0]
      }
      try {
        const response = await instance.acquireTokenSilent(request)
        return response.accessToken
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request)
          return response.accessToken
        }
      };
    }
  }

  const getProjectInformation = async (): Promise<void> => {
    try {
      resetCurrentStack()
      let userId = ''
      if (accounts[0] !== undefined) {
        // eslint-disable-nextline  @typescript-eslint/no-non-null-assertion
        userId = accounts[0]?.idTokenClaims?.uid as string
      }
      const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
      const apiUrl = process.env.REACT_APP_API_URL as string
      if (token !== undefined && id !== undefined) {
        const config = {
          method: 'get',
          url: `${apiUrl}/users/role?projectId=${id}&userId=${userId}`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
        const response = await axios(config)
        setProjectInformation(response.data.data.rows[0])
        console.log('project information', response.data.data.rows[0])
        const { ProjectMasterId, ProjectStatusMasterId, UserRoleMasterId, ProjectName } = response.data.data.rows[0] as ProjectInformation
        if ((ProjectMasterId != null) && (ProjectStatusMasterId != null)) {
          setProjectName(ProjectName)
          if (ProjectStatusMasterId === 2) {
            navigate(`/project/${ProjectMasterId}/review`)
          }
          if (ProjectStatusMasterId === 1) {
            if (UserRoleMasterId === 2) { // TODO: add comment for userrolemasterid
              navigate(`/project/${ProjectMasterId}/review`)
            }
          }
          if (ProjectStatusMasterId === 4) { // TODO: add comment for projectstatusmasterid
            navigate(`/project/${ProjectMasterId}/review`)
          }
          if (ProjectStatusMasterId === 7) {
            navigate(`/download/${ProjectMasterId}`)
          }
        }
        setIsLoading(false)
        return
      }
      throw new Error('getUserRole: missing parameters')
    } catch (error) {
      console.log(error)
    }
  }
  if (isLoading && projectInformation.ProjectStatusMasterId === 0 && projectInformation.UserRoleMasterId === 0) {
    return <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} paddingTop={'100px'}>
              <CircularProgress />
            </Box>
  }

  if (!isLoading && projectInformation.ProjectStatusMasterId === 1 && projectInformation.UserRoleMasterId === 1) {
    return (
      <Slide direction='up' in mountOnEnter unmountOnExit>
        <Container maxWidth={false} sx={{ mt: '20px', minHeight: 'calc(100vh - 164px)' }}>
          <StepperComponent activeStep={currentStack} />
          {currentStack === 0 && <CreatProjectForm projectName={projectName !== undefined ? projectName : ''} projectMasterId={Number(id)} showProjectCreateForm={true} />}
          {currentStack === 1 && <LabelledDataUpload projectName={projectName} projectMasterId={Number(id)} />}
          {currentStack === 2 && <UnLabelledDataUpload projectName={projectName} projectMasterId={Number(id)} />}
          {currentStack === 3 && <ReviewConfig projectName={projectName} projectMasterId={Number(id)} /> }
          <Box sx={{ py: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Button size="large"
              variant="contained"
              color="primary"
              disabled={currentStack === 0}
              onClick={previousStack} startIcon={<ArrowBackIos />} >Back</Button>
              {currentStack < 3 && <Typography>{currentStack + 1}/{3}</Typography>}
              {currentStack < 3 && <Button size="large"
              variant="contained"
              onClick={nextStack}
              disabled={!nextStackEnabled}
              color="primary" endIcon={<ArrowForwardIos />}>Next</Button>}
          </Box>
        </Container>
      </Slide>
    )
  }
  return <>Something went wrong</>
}
