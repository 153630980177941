import React, { useEffect, useState, type ReactElement } from 'react'
import { useMsal } from '@azure/msal-react'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { Container, CircularProgress, Box, Paper, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { ProcessSummaryTable } from '../components/ProcessSummay'
import { grey } from '@mui/material/colors'

export const ReviewPage = (): ReactElement<React.FC> => {
  const { instance, accounts } = useMsal()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [projectInformation, setProjectInformation] = useState({ ProjectMasterId: '', ProjectStatusMasterId: 0, UserGlobalId: '', UserRoleMasterId: 0, ProjectName: '' })

  useEffect((): void => {
    // eslint-disable-next-line
    (async (): Promise<void> => {
      await getProjectInformation()
      const { ProjectMasterId, ProjectStatusMasterId, UserGlobalId, UserRoleMasterId } = projectInformation
      console.log(ProjectMasterId, ProjectStatusMasterId, UserGlobalId, UserRoleMasterId)
    })()
  }, [projectInformation.ProjectMasterId])

  const getAccessToken = async (scope: string): Promise<string | undefined> => {
    if (accounts.length > 0) {
      const request = {
        scopes: [`${scope}`],
        account: accounts[0]
      }
      try {
        const response = await instance.acquireTokenSilent(request)
        return response.accessToken
      } catch (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          const response = await instance.acquireTokenPopup(request)
          return response.accessToken
        }
      };
    }
  }

  const getProjectInformation = async (): Promise<void> => {
    try {
      let userId = ''
      if (accounts[0] !== undefined) {
        // eslint-disable-nextline  @typescript-eslint/no-non-null-assertion
        userId = accounts[0]?.idTokenClaims?.uid as string
      }
      const token = await getAccessToken(process.env.REACT_APP_API_SCOPE as string)
      const apiUrl = process.env.REACT_APP_API_URL as string
      if (token !== undefined && id !== undefined) {
        const config = {
          method: 'get',
          url: `${apiUrl}/users/role?projectId=${id}&userId=${userId}`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
        const response = await axios(config)
        console.log(response.data.data.rows[0])
        setProjectInformation(response.data.data.rows[0])
        setIsLoading(false)
        return
      }
      throw new Error('getUserRole: missing parameters')
    } catch (error) {
      console.log(error)
    }
  }
  // swap statusmasterId after testing 1 to 2 and 2 to 1
  return (
    <Container maxWidth={false} sx={{ marginTop: '2rem' }}>
      {isLoading && projectInformation.ProjectStatusMasterId === 0 && projectInformation.UserRoleMasterId === 0 && <Box display={'flex'} alignItems={'center'} justifyContent={'center'} minHeight={'70vh'}>
        <CircularProgress />
      </Box>
      }
      {!isLoading && projectInformation.ProjectStatusMasterId === 1 && displayInfoBox('Review is not started yet')}
      {!isLoading && projectInformation.ProjectStatusMasterId === 4 && displayInfoBox('Preprocessing In-progress')}
      {!isLoading && projectInformation.ProjectStatusMasterId === 5 && displayInfoBox('Labelling In-progress')}
      {!isLoading && projectInformation.ProjectStatusMasterId === 6 && <div> <ProcessSummaryTable projectName={projectInformation.ProjectName} projectMasterId={ projectInformation.ProjectMasterId } /></div>}
    </Container>)
}
function displayInfoBox (text: string): React.ReactNode {
  return <Box display={'flex'} height={'90%'} alignItems={'center'} justifyContent={'center'}><Container><Paper sx={{ padding: 10, textAlign: 'center' }}> <Typography color={grey[700]} fontSize={18} fontWeight={'bold'}>{text}</Typography> </Paper></Container></Box>
}
